/** @jsxImportSource @emotion/react */
import {DateTime} from "luxon";
import {Button, Grid, Typography} from "@mui/material";
import React from 'react';
import {css} from '@emotion/react';

export interface IAppointmentSlot {
    start: DateTime;
    end: DateTime;
}

interface AppointmentSlotProps {
    slot: IAppointmentSlot;
    isSlotSelected: (slot: IAppointmentSlot) => boolean;
    handleSlotSelect: (slot: IAppointmentSlot) => void;
    classes: Record<string, string>;
}

const AppointmentSlot: React.FC<AppointmentSlotProps> = ({slot, isSlotSelected, handleSlotSelect, classes}) => {
    // const buttonStyles = css`
    //     margin: ${classes.chip.margin};
    //     padding: ${classes.chip.padding};
    //     ${isSlotSelected(slot) ? `
    //         background-color: ${classes.selected.backgroundColor};
    //         color: ${classes.selected.color};
    //     ` : ''}
    // `;

    const timeStyles = css`
      font-family: monospace;
      width: 45%;
      text-align: left;

      &:nth-of-type(2) {
        width: 10%;
        text-align: center;
      }

      &:last-of-type {
        text-align: right;
      }
    `;

    return (<Button
        key={slot.start.toMillis()}
        variant={isSlotSelected(slot) ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleSlotSelect(slot)}
        fullWidth
        className={classes.chip}
        css={css`margin-top: 8px;`}
    >
        <Grid container direction="row" alignItems="center">
            <Typography variant="body1" css={timeStyles}>
                {slot.start.toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
            <Typography variant="body1" css={timeStyles}>
                {' - '}
            </Typography>
            <Typography variant="body1" css={timeStyles}>
                {slot.end.toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
        </Grid>
    </Button>);
};

export default AppointmentSlot;
