/** @jsxImportSource @emotion/react */
import React from 'react';
import {css, useTheme} from '@emotion/react';
import Typography from '@mui/material/Typography';
import {Theme} from "@mui/material";

const Footer = () => {
    const theme = useTheme() as Theme;

    const styles = {
        footer: css`
          background-color: ${theme.palette.background.paper};
          padding: ${theme.spacing(3)};
          margin-top: ${theme.spacing(4)};
          border-top: 1px solid ${theme.palette.divider};
        `,
    };

    return (
        <footer css={styles.footer}>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                All rights reserved.
            </Typography>
        </footer>
    );
};

export default Footer;
