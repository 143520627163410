/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {adaptV4Theme} from '@mui/material/styles';
import {useParams} from "react-router-dom";
import AppointmentSlotPicker from "./AppointmentSlotPicker";

import {IAppointmentSlot} from "./AppointmentSlot";
import {DateTime} from "luxon";
import {
    Box,
    Button,
    Card,
    CardContent,
    createTheme,
    CssBaseline,
    Grid,
    StyledEngineProvider,
    ThemeProvider,
    Typography,
} from '@mui/material';
import {PageWrapper} from "./PageWrapper";
import MonthViewCalendar from "./MonthViewCalendar";


// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import moment from 'moment';
// const localizer = momentLocalizer(moment);

const theme = createTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#4CAF50',
        },
        secondary: {
            main: '#f44336',
        },
    },
}));


let AppointmentScheduler = function () {
    console.log(process.env.REACT_APP_API_URL);
    const [availableSlots, setAvailableSlots] = useState<IAppointmentSlot[]>([]);
    const {user, slot} = useParams();

    const [days, setDays] = useState(() => {
        const today = DateTime.local().startOf('day');
        return Array.from({length: 3}, (_, i) => today.plus({days: i}));
    });

    const [selectedDate, setSelectedDate] = useState<DateTime>(
        DateTime.local().startOf('day')
    );

    useEffect(() => {
        const startDate = DateTime.now()
            .setZone('America/Los_Angeles')
            .plus({days: 1})
            .startOf('day');
        fetchSlots(startDate, 3).catch(console.error);
    }, []);

    async function fetchSlots(startDate: DateTime, numDays: number) {
        // Fetch available slots for the given date range
        const apiUrl = `${process.env.REACT_APP_API_URL}/users/${user}/slots/${slot}/availableSlots`;
        const urlWithDateRange = `${apiUrl}?start=${startDate.toISODate()}&numDays=${numDays}`;

        let response = await fetch(urlWithDateRange);
        let userAvailableSlots: IAppointmentSlot[] = (await response.json()).map((slot: any) => {
            return {
                start: DateTime.fromISO(slot.start),
                end: DateTime.fromISO(slot.end),
            };
        });

        // Find the unique days by date and take the first three
        const uniqueDays = Array.from(
            new Set(userAvailableSlots.map((slot: any) =>
                slot.start.startOf('day').toISODate()))
        ).map((date) => DateTime.fromISO(date));
        const firstThreeDays = uniqueDays.slice(0, 3);

        setDays(firstThreeDays);
        setAvailableSlots(userAvailableSlots);
    }

    const handleNextDays = async () => {
        const nextDays = days.map((day) => day.plus({days: 3}));
        const startDate = nextDays[0];
        const numDays = 3;

        // Check if there are any available slots for the middle day
        const midDay = days[1].plus({days: 1});
        const midDaySlots = availableSlots.filter((slot) =>
            slot.start.hasSame(midDay, 'day')
        );

        if (midDaySlots.length === 0) {
            // If no slots are available, skip the middle day and go to the next day
            const nextStartDate = days[2].plus({days: 1});
            setDays([nextStartDate, ...nextDays.slice(1)]);
            fetchSlots(nextStartDate, numDays).catch(console.error);
        } else {
            // Otherwise, continue with the next three days as usual
            setDays(nextDays);
            fetchSlots(startDate, numDays).catch(console.error);
        }
    };


    const handlePrevDays = async () => {
        const prevDays = days.map((day) => day.minus({days: 3}));
        const startDate = prevDays[0];
        const numDays = 3;

        // Check if there are any available slots for the middle day
        const midDay = prevDays[1].plus({days: 1});
        const midDaySlots = availableSlots.filter((slot) =>
            slot.start.hasSame(midDay, 'day')
        );

        if (midDaySlots.length === 0) {
            // If no slots are available, skip the middle day and go to the previous day
            const prevStartDate = prevDays[0].minus({days: 3});
            setDays([...prevDays.slice(0, 2), prevStartDate]);
            fetchSlots(prevStartDate, numDays).catch(console.error);
        } else {
            // Otherwise, continue with the previous three days as usual
            setDays(prevDays);
            fetchSlots(startDate, numDays).catch(console.error);
        }
    };

    const handleDateChange = (date: DateTime) => {
        setSelectedDate(date);
        fetchSlots(date, 3).catch(console.error);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <PageWrapper>
                    <Grid container>
                        <Grid item md={4}>
                            <Card style={{height: '100%'}}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Schedule an appointment with {user}
                                    </Typography>
                                    <Typography color="textSecondary">{slot}</Typography>
                                    <Box mt={4}>
                                        <MonthViewCalendar onDateChange={handleDateChange}/>
                                    </Box>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
                                <Button onClick={handlePrevDays}>&laquo; Previous</Button>
                                <Button onClick={handleNextDays}>Next &raquo;</Button>
                            </Box>
                            <Grid container>
                                {days.map((day, index) => {
                                    if (!availableSlots.some(slot => slot.start.hasSame(day, 'day'))) {
                                        return null;
                                    }

                                    return (
                                        <Grid item xs={12} md={4} key={index}>
                                            <AppointmentSlotPicker slots={availableSlots} day={day}/>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </PageWrapper>
            </ThemeProvider>
        </StyledEngineProvider>
    );

}

export default AppointmentScheduler;
