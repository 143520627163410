// @ts-ignore
import {Box, Container} from "@mui/material";
import {Header} from "./Header";
import Footer from "./Footer";
import React from "react";

// @ts-ignore
export const PageWrapper = ({children}) => {
    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <Header/>
            <Box flexGrow={1}>
                <Container maxWidth="lg">
                    <Box py={4}>{children}</Box>
                </Container>
            </Box>
            <Footer/>
        </Box>
    );
};