/** @jsxImportSource @emotion/react */
import {useState} from 'react';
import {Card, CardContent, CardHeader, Divider, Grid, styled,} from '@mui/material';
import {DateTime} from 'luxon';
import AppointmentSlot, {IAppointmentSlot} from './AppointmentSlot';
import AppointmentDialog from './AppointmentDialog';

interface AppointmentSlotProps {
    slots: IAppointmentSlot[];
    day: DateTime;
    style?: React.CSSProperties;
}

function isSameDay(date1: DateTime, date2: DateTime) {
    return (
        date1.day === date2.day &&
        date1.month === date2.month &&
        date1.year === date2.year
    );
}

const CardWrapper = styled(Card)(({theme}) => ({
    margin: theme.spacing(2),
}));

const AppointmentSlotPicker: React.FC<AppointmentSlotProps> = ({
                                                                   slots,
                                                                   day,
                                                                   style,
                                                               }) => {
    const [selectedSlot, setSelectedSlot] =
        useState<IAppointmentSlot | null>(null);
    const [open, setOpen] = useState(false);
    const filteredSlots = slots.filter((slot) => isSameDay(slot.start, day));

    const handleSlotSelect = (slot: IAppointmentSlot) => {
        setSelectedSlot(slot);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBookAppointment = (email: string) => {
        // make API call to book appointment with email and selectedSlot
    };

    const isSlotSelected = (slot: IAppointmentSlot) => {
        return selectedSlot !== null && selectedSlot.start.equals(slot.start);
    };

    //   const slotStyles = css`
    //   // // &.MuiButton-containedPrimary {
    //   // //   background-color: ${({ theme }) => theme.palette.primary.main};
    //   // //   color: ${({ theme }) => theme.palette.common.white};
    //   // // }
    //   // //
    //   // // &.MuiButton-outlinedPrimary {
    //   // //   border-color: ${({ theme }) => theme.palette.primary.main};
    //   // //   color: ${({ theme }) => theme.palette.primary.main};
    //   // // }
    //   //
    //   // &.selected {
    //   //   background-color: ${({ theme }) => theme.palette.primary.main};
    //   //   color: ${({ theme }) => theme.palette.common.white};
    //   // }
    //   //
    //   //   &.chip {
    //   //     margin: 8px;
    //   //   }
    // `;

    return (
        <Grid container justifyContent="center">
            <Grid item>
                <CardWrapper sx={{margin: '8px'}}>
                    <CardHeader
                        title={day.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                    />
                    <Divider/>
                    <CardContent>
                        {filteredSlots.map((slot) => (
                            <AppointmentSlot
                                slot={slot}
                                isSlotSelected={isSlotSelected}
                                handleSlotSelect={handleSlotSelect}
                                classes={{chip: 'slotStyles', selected: 'selected'}}
                                key={slot.start.toMillis()}

                            />
                        ))}
                    </CardContent>
                </CardWrapper>
            </Grid>
            <AppointmentDialog
                open={open}
                slot={selectedSlot!}
                onClose={handleClose}
                handleBookAppointment={handleBookAppointment}
                selectedSlot={selectedSlot}
            />
        </Grid>
    );
};

export default AppointmentSlotPicker;
