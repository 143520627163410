import React, {Component} from 'react';
import './App.css';

class App extends Component {
    constructor() {
        super({});
        this.state = {data: []};
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    Scheduling
                </header>

            </div>
        );
    }
}

export default App;
