import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {DateTime} from "luxon";

interface MonthViewCalendarProps {
    onDateChange: (date: DateTime) => void;
}

const MonthViewCalendar: React.FC<MonthViewCalendarProps> = ({onDateChange}) => {
    const [selectedDate, setSelectedDate] = useState(DateTime.now());

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setSelectedDate(DateTime.fromJSDate(date));
            onDateChange(DateTime.fromJSDate(date));
        }
    };

    return (
        <div>
            <h2>Select a date:</h2>
            <DatePicker

                inline={true}
                selected={selectedDate.toJSDate()}
                onChange={handleDateChange}
                minDate={DateTime.now().toJSDate()}

            />
        </div>
    );
};

export default MonthViewCalendar;
