/** @jsxImportSource @emotion/react */
import {AppBar, Theme, Toolbar, Typography, useTheme} from '@mui/material';
import React from 'react';
import {css} from '@emotion/react';

const useStyles = (theme: Theme) => ({
    appbar: css({
        marginBottom: theme.spacing(4),
    }),
    title: css({
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        padding: theme.spacing(1),
    }),
});

export const Header = () => {
    const classes = useStyles(useTheme());

    return (
        <AppBar position="static" css={classes.appbar}>
            <Toolbar>
                <Typography variant="h6" css={classes.title}>
                    Appointment Scheduler
                </Typography>
            </Toolbar>
        </AppBar>
    );
};
