/** @jsxImportSource @emotion/react */
import {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextareaAutosize,
    TextField,
} from '@mui/material';
import {IAppointmentSlot} from './AppointmentSlot';
import {css} from '@emotion/react';

interface AppointmentDialogProps {
    open: boolean;
    slot: IAppointmentSlot;
    onClose: () => void;
    handleBookAppointment: (email: string, name: string, message: string, guests: string[]) => void;
    selectedSlot: IAppointmentSlot | null;
}

const dialogStyle = css`
  //max-width: 500px;
  border-radius: 8px;
`;

const dialogTitleStyle = css`
  padding: 16px;
  background-color: #4d4d4d;
  color: white;
  border-radius: 8px 8px 0 0;
`;

const dialogContentStyle = css`
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 0 0 8px 8px;
`;

const dialogActionsStyle = css`
  padding: 16px;
`;

const submitButtonStyle = css`
  background-color: #4d4d4d;
  color: white;

  &:hover {
    background-color: #6d6d6d;
  }
`;

const AppointmentDialog: React.FC<AppointmentDialogProps> = ({open, slot, onClose, handleBookAppointment}) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [guests, setGuests] = useState<string[]>([]);

    const handleClose = () => {
        setEmail('');
        setName('');
        setMessage('');
        setGuests([]);
        onClose();
    };

    const handleSubmit = () => {
        handleBookAppointment(email, name, message, guests);
        setEmail('');
        setName('');
        setMessage('');
        setGuests([]);
    };

    const handleAddGuest = () => {
        setGuests([...guests, '']);
    };

    const handleGuestChange = (index: number, value: string) => {
        const newGuests = [...guests];
        newGuests[index] = value;
        setGuests(newGuests);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            sx={{
                "& .MuiDialog-container": {
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }}
            PaperProps={{
                style: {
                    borderRadius: 8,
                    backgroundColor: '#F6F6F6',
                    boxShadow: 'none',
                },
            }}
            css={dialogStyle}
        >
            <DialogTitle css={dialogTitleStyle}>Book Appointment</DialogTitle>
            <DialogContent css={dialogContentStyle}>
                <DialogContentText>
                    You have selected the following appointment slot:
                    <br/>
                    {slot?.start.toFormat('DDDD t')} - {slot?.end.toFormat('t')}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {guests.map((guest, index) => (
                    <TextField
                        margin="dense"
                        id={`guest-${index}`}
                        label={`Guest ${index + 1}`}
                        type="email"
                        fullWidth
                        value={guest}
                        onChange={(e) => {
                            const updatedGuests = [...guests];
                            updatedGuests[index] = e.target.value;
                            setGuests(updatedGuests);
                        }}
                        key={index}
                    />
                ))}
                <Button
                    onClick={() => {
                        handleAddGuest();
                    }}
                    style={{marginTop: '1rem'}}
                >
                    + Add Additional Guest
                </Button>
                <TextareaAutosize
                    minRows={3}
                    placeholder="Anything else you'd like to share for our meeting?"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{width: '100%', margin: '1rem 0'}}
                />
            </DialogContent>
            <DialogActions css={dialogActionsStyle}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary" css={submitButtonStyle}>
                    Book
                </Button>
            </DialogActions>
        </Dialog>
    );


};

export default AppointmentDialog;
