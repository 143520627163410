import React, {useEffect, useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';


let Auth = function () {
    console.log(process.env.REACT_APP_API_URL);
    let [loggedIn, setLoggedIn] = useState(false);

    async function gatherResponse(response: Response) {
        const {headers} = response;
        const contentType = headers.get("content-type") || "";
        if (contentType.includes("application/json")) {
            return JSON.stringify(await response.json());
        }
        return response.text();
    }

    let username = "neonitb";

    async function checkAuth() {

        let response = await fetch(process.env.REACT_APP_API_URL + '/users/' + username + '/auth/check', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        let body = gatherResponse(response);
        if (response.status === 200) {
            console.log('logged in');
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }

    useEffect(() => {
        checkAuth().catch(console.error);
    }, []);

    let login = useGoogleLogin({
        onSuccess: function (codeResponse) {
            // Send the code to API server
            fetch(process.env.REACT_APP_API_URL + '/users/' + username + '/auth/google', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(codeResponse)
            }).catch(console.error);
        },
        flow: 'auth-code',
        redirect_uri: 'http://localhost:3000/auth/callback',
        scope: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/script.projects'
    });


    // async componentDidMount() {
    //     // let urlPath = window.location.pathname;
    //     // let urlPathArray = urlPath.split('/');
    //     // let username = urlPathArray[0];
    //     // let userSlotId = urlPathArray[1];
    //     // let userAvailableSlots = await fetch('http://localhost:8787/api/v1/users/' + username + '/slots/' + userSlotId + '/availableSlots');
    //     // this.setState({data: userAvailableSlots});
    //     // // fetch('http://localhost:8787/api/v1/users/' + username + '/slots/' + userSlotId + '/availableSlots')
    //     // //     .then(response => response.json())
    //     // //     .then(data => this.setState({data}));
    // }

    return (
        <div className="Auth">
            {loggedIn ? 'Logged In' : <button onClick={() => login()}>
                Sign in with Google 🚀{' '}
            </button>}
        </div>
    );


}

export default Auth;
