import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Auth from "./Auth";
import AppointmentScheduler from "./AppointmentScheduler";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/auth",
        element: <Auth/>
    },
    {
        path: "/:user/:slot",
        element: <AppointmentScheduler/>
    },
]);

const root = document.getElementById("root") as HTMLElement

const theme = createTheme();
render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="741758196852-p8r5tl3dtmo3s2ms3pogj3t4089tnhfr.apps.googleusercontent.com">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}><RouterProvider router={router}/></ThemeProvider>
            </StyledEngineProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>, root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
